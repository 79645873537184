import React from "react";

interface IPageNotFoundProps {
  error?: string;
}

const genericErrorMessage: string = "Looks like you've come across an empty page.";

const PageNotFound = ({ error = genericErrorMessage }: IPageNotFoundProps) => {
  return <div>404 not found</div>;
  // return (
  //   <GlobalLayout siteMetadata={{ name: "404 Page Not Found | Cro Metrics Support Wiki", description: "No page content found" }}>
  //     <div className="PageNotFound">
  //       <h1 className="PageNotFound__error">404: Page not found</h1>
  //       <div className="PageNotFound__details">{error}</div>
  //       <Link to="/">
  //         <span>Don't worry, here's a safe way back</span>
  //         <ArrowRightSVG/>
  //       </Link>
  //     </div>
  //   </GlobalLayout>
  // );
};
export default PageNotFound;